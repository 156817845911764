$color-white: white;
$color-black: black;

$color-action: #00B300;
$color-action-dark: #009300;
$color-error: #E02020;
$color-error-light: #FFDFDF;

$color-base-lightest: #FAF9F8;
$color-base-lighter: #D2D2D2;
$color-base-light: #BEBCBA;
$color-base: #BEBCBA;
$color-base-dark: #A1A1A1;
$color-base-darker: #8C8C8C;

$color-main-lightest: #F2F2F7;
$color-main-lighter: #5E7079;
$color-main-light: #536080;
$color-main: #1D2C4C;
$color-main-dark: #2C3034;
$color-main-darker: #3E3B3A;

$color-info: #007AFF;

$color-shadow: #ECECEC;

$color-highlight: #00C9E4;

$font-main: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

$shadow-l: 6px 0 9px 0 $color-shadow;
$shadow-m: 0 0 5px 0 $color-shadow;
$shadow-dark: 0 4px 6px 0 rgba($color-main-darker, 0.5);

$radius-s: 2px;
$radius-m: 4px;
$radius-l: 9px;

$font-size-xxxl: 40px;
$font-size-xxl: 28px;
$font-size-xl: 25px;
$font-size-l: 20px;
$font-size-m: 18px;
$font-size-s: 16px;
$font-size-xs: 14px;
$font-size-xxs: 12px;
$font-size-xxxs: 10px;

$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

.title-l {
  font-size: $font-size-l;
  line-height: 32px;
  letter-spacing: -0.36px;
  color: $color-main;
  font-weight: $font-weight-bold;
}

.title-xxl {
  font-size: $font-size-xxl;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: $color-main-darker;
  font-weight: $font-weight-bold;
  margin-bottom: 34px;
}
