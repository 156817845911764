.button-filter {
  display: none;
}

.products {
  width: calc(100% - 24px);
  margin-left: 24px;

  &-notFound {
    color: $color-main-darker;
    line-height: 18px;
    font-size: $font-size-xs;
    color: $color-base-darker;
    
    &-content {
      margin: 0 auto;
      text-align: center;
      max-width: 290px;
      &:before {
        content: "";
        display: inline-block;
        width: 91px;
        height: 103px;
        background: url("../../images/notfound.png") no-repeat center;
        margin-bottom: 10px;
      }
    }

    background-color: $color-white;
    border-radius: $radius-m;
    padding: 20px 20px 50px;
    box-shadow: $shadow-m;
    font-size: $font-size-xs;

    &-title {
      font-size: $font-size-l;
      color: $color-main-darker;
      font-weight: $font-weight-bold;
      margin-bottom: 6px;
    }
  }

  &-header {
    color: $color-base-darker;
    &-title {
      font-size: $font-size-xl;
      color: $color-main;
      margin-bottom: 10px;
    }

    &-subtitle {
      font-size: $font-size-m;
      font-weight: $font-weight-regular;
      margin-bottom: 45px;
    }
  }

  &-list {
    &-centered {
      justify-content: center;
    }

    &-wrapper {
      display: flex;
      line-height: 16px;
      justify-content: space-between;
      margin-bottom: 16px;
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;
      align-items: flex-end;
    }

    &-info {
      color: $color-base-darker;
    }

    &-results {
      color: $color-main;
      margin-left: 10px;
      white-space: nowrap;
    }

    &-item {
      &:not(:first-child) {
        margin-top: 14px;
      }

      color: $color-main-lighter;
      background-color: $color-white;
      border-radius: $radius-m;
      padding: 10px 0;
      box-shadow: $shadow-m;
      font-size: $font-size-xs;
      
      > * {
        display: flex;
        text-align: center;
        > li {
          padding: 10px 15px;
          width: 16.666%;
          border-right: solid 1px $color-main-lightest;
          display: flex;
          align-items: center;
          flex-direction: column;
          &:last-child {
            border-right: 0;
          }
        }
      }

      &-title {
        font-size: $font-size-l;
        line-height: 32px;
        padding: 4px 0;
        font-weight: $font-weight-bold;
        color: $color-main;
      }

      &-subtitle {
        font-size: $font-size-xxxs;
      }
    }

    &-highlight {
      background-color: $color-main-lightest;
    }

    &-logo {
      width: 83px;
    }

    &-cta {
      width: 100%;
    }
  }

  &-detail {
    &-list {
      margin-bottom: 40px;
      position: relative;
      display: flex;
      padding-top: 110px;
      font-size: $font-size-xs;
      color: $color-main-lighter;
      > ul {
        width: 50%;
        padding: 0 14px;

        > li {
          margin-bottom: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: solid 2px $color-main-lightest;
          padding: 12px 0;
          min-height: 56px;
        }

      }

      &-title {
        color: $color-main;
        font-weight: $font-weight-bold;
        font-size: $font-size-s;
      }

      &-subtitle {
        margin-top: 6px;
        color: $color-base-darker;
        font-size: $font-size-xxxs;
      }

      &-logo {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        top: -30px;
        height: 96px;
      }

      &-cta {
        text-align: center;
      }

      &-disclaimer {
        margin-top: 50px;
        font-size: $font-size-xxxs;
        line-height: 12px;
        padding: 0 20px;
        color: $color-base-darker;

        > p {
          margin-bottom: 20px;
          &.highlight {
            color: $color-main-dark;
          }
        }
      }
    }
  }
}

@include bp(small_desktop) {
  .products {
    &-list {
      &-item {
        padding: 0;
        > * {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          > li {
            width: auto;
            border-right: none;
            margin: 0 10px;
            padding: 10px 5px;
            &.products-list-dealTerm, &.products-list-initialRate, &.products-list-fees {
              position: relative;
              grid-column: 1 / 4;
              border-bottom: solid 1px $color-main-lightest;
              text-align: left;
              display: inline;
              padding-left: 0;
              padding-right: 0;
              .products-list-item-title {
                position: absolute;
                padding: 0;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
              }
            }
          }
        }
        &-title {
          font-size: $font-size-s;
        }
      }
      &-button {
        .button-action {
          margin-top: 10px;
          padding: 20px 0;
          text-transform: uppercase;
          border-radius: 40px;
        }
      }
      &-logo {
        grid-column: 1 / 6;
        grid-row: 1;
      }
      &-dealTerm {
        grid-row: 2;

      }
      &-initialRate {
        grid-row: 3;

      }
      &-fees {
        grid-row: 4;
      }
      &-highlight {
        justify-content: center;
        grid-column: 4 / 6;
        grid-row: 2 / 5;
        font-size: $font-size-xxs;
        .products-list-item-title {
          font-size: $font-size-l;
        }
      }
      &-button {
        grid-column: 1 / 6;
        grid-row: 5;
      }
    }
  }
}

@include bp(mobile) {
  .button-filter {
    display: block;
  }

  .products {
    margin-left: 0;
    width: 100%;
    &-header {
      &-subtitle {
        margin-bottom: 20px;
      }
    }
    &-detail {
      &-list {
        display: block;
        
        > ul {
          width: 100%;

          > li {
            min-height: auto;
          }
        }
      }
    }
  }
}
