.success {
  margin: 0 auto;
  text-align: center;
  padding-top: 240px;
  color: $color-main-light;
  width: 440px;
  max-width: 100%;
  line-height: 18px;
  background: url('../../images/success.png') top center no-repeat;

  &-button {
    margin-top: 50px;
    width: 100%;
  }
}
