.aside {
  width: 30%;
  min-width: 310px;
  max-width: 370px;
  &-content {
    position: sticky;
    top: 24px;
    box-shadow: $shadow-m;
    border-radius: $radius-m;
    background-color: $color-white;
    padding: 24px 24px 30px;
    border-bottom: solid 6px $color-base-lighter;
  }

  &-close {
    display: none;
  }

  &-cta {
    display: none;
    padding: 20px;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    &:before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      height: 20px;
      width: 100%;
      background: linear-gradient(to bottom, rgba($color-white,0) 0%,rgba($color-white,1) 100%); 
    }
  }
}

@include bp(mobile) {
  .aside {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    max-width: 100%;
    transition: left ease-in-out 0.2s;

    &-close {
      display: block;
      position: absolute;
      right: 20px;
      top: 20px;
    }
    
    &-cta {
      display: block;
      transition: opacity ease 0.2s;
      opacity: 0;
      visibility: hidden;
    }

    &-content {
      position: relative;
      box-shadow: none;
      border-radius: 0;
      top: 0;
      height: 100%;
      overflow: auto;
      border-bottom: none;
      padding-bottom: 100px;
    }
  }
}
