.footer {
  margin-top: 40px;
  background-color: $color-main-darker;
  padding: 20px 40px;
  color: $color-white;
  font-size: $font-size-xxxs;
  line-height: 14px;
  > p {
    margin-bottom: 10px;
  }
}
