:root {
  --color-tuna: #373C43;
  --color-white: #FFF;
  --color-pale-sky: #6D757B;
  --color-mandy: #E9596A;
  --color-shark: #2D3136;
  --color-black: #000;
  --color-seashell: #F1F1F1;
  --line-height: 20px;
}

.dd__wrapper {
  position: relative;
}

.dd__opened {
  .dd__selected:after {
    transform: rotate(180deg);
  }
}

.dd__disabled {
  border-color: var(--color-pale-sky);
  color: var(--color-pale-sky);
}

.dd__error {
  border-color: var(--color-mandy);
}

  .dd__selectControl {
    border: solid 1px $color-base-lighter;
    border-radius: $radius-m;
    display: flex;
    height: 60px;
    cursor: pointer;
    overflow: hidden;

    @nest .dd__error & {
      color: var(--color-mandy);
      user-select: none;
    }

    @nest .dd__disabled & {
      cursor: default;
    }
  }

    .dd__selected {
      position: relative;
      padding: 0 40px 0 16px;
      width: 100%;
      font-size: $font-size-m;
      color: $color-main-lighter;
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-wrap: wrap;
      overflow: hidden;

      @nest .dd__multi & {
        margin-top: -2px;
      }

      &:after {
        content: "";
        position: absolute;
        right: 16px;
        top: calc(50% - 5px);
        height: 10px;
        width: 15px;
        transition: transform ease-in-out 0.2s;
        background: url("../../../images/select.svg") center center no-repeat;
      }
    }

      .dd__search {
        display: inline-block;
        align-items: center;
        flex-grow: 1;
        border: 1px solid transparent;
        outline: 0;
        opacity: 0;
        min-height: var(--line-height);
        word-break: break-word;

        @nest .dd__multi & {
          margin-top: 2px;
        }

        @nest .dd__opened & {
          opacity: 1;
        }
      }

      .dd__placeholder {
        border: 1px solid transparent;
        color: var(--color-pale-sky);

        @nest .dd__multi & {
          margin-top: 2px;
        }

        @nest .dd__error & {
          color: var(--color-mandy);
          user-select: none;
        }
      }

      .dd__selectedItem {
        display: flex;

        @nest .dd__multi & {
          border-color: #A9B6D2;
          background-color: #E3EAF6;
          border-radius: 2px;
          margin-top: 2px;
          margin-right: 2px;
          padding: 0 4px;
        }
      }

        .dd__crossIcon {
          margin-left: 5px;
        }

    .dd__expandIcon {
      display: none;
      width: 9px;
      height: 15px;
      flex-shrink: 0;
      fill: var(--color-shark);

      @nest .dd__error & {
        fill: var(--color-mandy);
      }
    }

  .dd__list {
    max-height: 200px;
    position: absolute;
    margin-top: -5px;
    padding-bottom: 6px;
    background-color: var(--color-white);
    overflow: auto;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
    min-width: 100%;
    border: solid 1px $color-base-lighter;
    border-top: none;
    border-radius: $radius-m;

    /* Without this property, Chrome repaints the entire Grid any time a new row or column is added.
       Firefox only repaints the new row or column (regardless of this property).
       Safari and IE don't support the property at all. */
    will-change: transform;

    @nest .dd__opened & {
      display: block;

      &:empty {
        display: none;
      }
    }
  }

  .dd__listVirtualized {
    overflow-y: auto;
    overflow-x: hidden;

    :global(.Grid__innerScrollContainer) {
      overflow: hidden;
      position: relative;
    }
  }

    .dd__optionVirtualized {
      position: absolute;
    }

      .dd__option {
        margin: 6px;
        padding: 10px;
        color: $color-main-light;
        border-radius: $radius-m;
        white-space: nowrap;
        cursor: pointer;

        &.selected {
          display: none;
        }

        &:hover {
          background-color: $color-main-lightest;
        }
      }

      .dd__selectedOption {
        background-color: var(--color-seashell);
      }

      .dd__optionDisabled {
        cursor: default;

        &:hover {
          background-color: transparent;
        }
      }
