.productSelector {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  &-wrapper {
    width: 320px;
    max-width: 100%;
  }

  &-subtitle {
    color: $color-base-darker;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    margin-bottom: 14px;
  }

  &-list {
    border: solid 1px $color-base-lighter;
    background-color: $color-white;
    border-radius: $radius-l;

    > li {
      a {
        text-decoration: none;
        display: block;
        position: relative;
        color: $color-main-darker;
        font-size: $font-size-s;
        font-weight: $font-weight-bold;
        padding: 20px 40px 20px 16px;

        &:before {
          content: "";
          transform: rotate(-90deg) translateX(50%);
          position: absolute;
          top: 50%;
          right: 16px;
          background: url("../../images/select.svg") center center no-repeat;
          background-size: 12px;
          height: 15px;
          width: 15px;
        }
      }

      &:not(:last-child) a {
        border-bottom: solid 1px $color-base-lighter;
      }
    }
  }
}
