* {
  outline: none;

  &, &:before, &:after {
    box-sizing: border-box;
  }
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: 100%;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

a {
  cursor: pointer;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

input:not([type=checkbox]):not([type=radio]), textarea {
  appearance: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type=number] {
  appearance: textfield;
}

input::-ms-clear {
  display: none;
}

select::-ms-expand {
  display: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

input::placeholder {
  opacity: 1;
}

input, textarea {
  &:disabled {
    opacity: 1;
  }
}
