@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
* {
  outline: none;
}
*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: 100%;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

a {
  cursor: pointer;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

input:not([type=checkbox]):not([type=radio]), textarea {
  appearance: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type=number] {
  appearance: textfield;
}

input::-ms-clear {
  display: none;
}

select::-ms-expand {
  display: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

input::placeholder {
  opacity: 1;
}

input:disabled, textarea:disabled {
  opacity: 1;
}

.title-l {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.36px;
  color: #1D2C4C;
  font-weight: 700;
}

.title-xxl {
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #3E3B3A;
  font-weight: 700;
  margin-bottom: 34px;
}

.bold {
  font-weight: 700;
}

body {
  background-color: #FAF9F8;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.layout {
  padding: 32px;
}

a {
  color: #00C9E4;
}

@media (max-width: 989px) {
  .layout {
    padding: 24px 16px;
  }
}
@media (max-width: 767px) {
  .showMenu .products, .showMenu .header, .showMenu .footer {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  .showMenu .aside {
    left: 0;
  }
  .showMenu .aside .aside-cta {
    opacity: 1;
    visibility: visible;
  }
}
button {
  border: none;
  cursor: pointer;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
button:disabled, button:disabled:hover {
  background-color: rgba(0, 179, 0, 0.12);
  cursor: not-allowed;
}

.button-action {
  text-decoration: none;
  display: inline-block;
  border-radius: 24px;
  background-color: #00B300;
  color: white;
  padding: 16px 0;
  font-size: 14px;
  font-weight: 600;
  border: none;
  transition: background-color 0.3s ease;
}
.button-action:hover {
  background-color: #009300;
}
.button-filter {
  text-decoration: none;
  display: inline-block;
  border-radius: 24px;
  background-color: #007AFF;
  color: white;
  padding: 14px 30px;
  font-size: 12px;
  font-weight: 600;
  border: none;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}
.button-filter:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url("../../images/refine.svg") top center no-repeat;
  background-size: cover;
  margin-right: 10px;
  margin-bottom: -2px;
}
.button-filter:hover {
  background-color: #006ee6;
}
.button-big {
  width: 315px;
  max-width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 32px;
  background-color: white;
  box-shadow: 6px 0 9px 0 #ECECEC;
}
.header-logo {
  height: 35px;
}
.header-close {
  background: url("../../images/close.svg") center center no-repeat #F2F2F7;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

@media (max-width: 989px) {
  .header {
    padding: 0 20px;
  }
}
.footer {
  margin-top: 40px;
  background-color: #3E3B3A;
  padding: 20px 40px;
  color: white;
  font-size: 10px;
  line-height: 14px;
}
.footer > p {
  margin-bottom: 10px;
}

.aside {
  width: 30%;
  min-width: 310px;
  max-width: 370px;
}
.aside-content {
  position: sticky;
  top: 24px;
  box-shadow: 0 0 5px 0 #ECECEC;
  border-radius: 4px;
  background-color: white;
  padding: 24px 24px 30px;
  border-bottom: solid 6px #D2D2D2;
}
.aside-close {
  display: none;
}
.aside-cta {
  display: none;
  padding: 20px;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
}
.aside-cta:before {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  height: 20px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
}

@media (max-width: 767px) {
  .aside {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    max-width: 100%;
    transition: left ease-in-out 0.2s;
  }
  .aside-close {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .aside-cta {
    display: block;
    transition: opacity ease 0.2s;
    opacity: 0;
    visibility: hidden;
  }
  .aside-content {
    position: relative;
    box-shadow: none;
    border-radius: 0;
    top: 0;
    height: 100%;
    overflow: auto;
    border-bottom: none;
    padding-bottom: 100px;
  }
}
.button-filter {
  display: none;
}

.products {
  width: calc(100% - 24px);
  margin-left: 24px;
}
.products-notFound {
  color: #3E3B3A;
  line-height: 18px;
  font-size: 14px;
  color: #8C8C8C;
  background-color: white;
  border-radius: 4px;
  padding: 20px 20px 50px;
  box-shadow: 0 0 5px 0 #ECECEC;
  font-size: 14px;
}
.products-notFound-content {
  margin: 0 auto;
  text-align: center;
  max-width: 290px;
}
.products-notFound-content:before {
  content: "";
  display: inline-block;
  width: 91px;
  height: 103px;
  background: url("../../images/notfound.png") no-repeat center;
  margin-bottom: 10px;
}
.products-notFound-title {
  font-size: 20px;
  color: #3E3B3A;
  font-weight: 700;
  margin-bottom: 6px;
}
.products-header {
  color: #8C8C8C;
}
.products-header-title {
  font-size: 25px;
  color: #1D2C4C;
  margin-bottom: 10px;
}
.products-header-subtitle {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 45px;
}
.products-list-centered {
  justify-content: center;
}
.products-list-wrapper {
  display: flex;
  line-height: 16px;
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 700;
  align-items: flex-end;
}
.products-list-info {
  color: #8C8C8C;
}
.products-list-results {
  color: #1D2C4C;
  margin-left: 10px;
  white-space: nowrap;
}
.products-list-item {
  color: #5E7079;
  background-color: white;
  border-radius: 4px;
  padding: 10px 0;
  box-shadow: 0 0 5px 0 #ECECEC;
  font-size: 14px;
}
.products-list-item:not(:first-child) {
  margin-top: 14px;
}
.products-list-item > * {
  display: flex;
  text-align: center;
}
.products-list-item > * > li {
  padding: 10px 15px;
  width: 16.666%;
  border-right: solid 1px #F2F2F7;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.products-list-item > * > li:last-child {
  border-right: 0;
}
.products-list-item-title {
  font-size: 20px;
  line-height: 32px;
  padding: 4px 0;
  font-weight: 700;
  color: #1D2C4C;
}
.products-list-item-subtitle {
  font-size: 10px;
}
.products-list-highlight {
  background-color: #F2F2F7;
}
.products-list-logo {
  width: 83px;
}
.products-list-cta {
  width: 100%;
}
.products-detail-list {
  margin-bottom: 40px;
  position: relative;
  display: flex;
  padding-top: 110px;
  font-size: 14px;
  color: #5E7079;
}
.products-detail-list > ul {
  width: 50%;
  padding: 0 14px;
}
.products-detail-list > ul > li {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 2px #F2F2F7;
  padding: 12px 0;
  min-height: 56px;
}
.products-detail-list-title {
  color: #1D2C4C;
  font-weight: 700;
  font-size: 16px;
}
.products-detail-list-subtitle {
  margin-top: 6px;
  color: #8C8C8C;
  font-size: 10px;
}
.products-detail-list-logo {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: -30px;
  height: 96px;
}
.products-detail-list-cta {
  text-align: center;
}
.products-detail-list-disclaimer {
  margin-top: 50px;
  font-size: 10px;
  line-height: 12px;
  padding: 0 20px;
  color: #8C8C8C;
}
.products-detail-list-disclaimer > p {
  margin-bottom: 20px;
}
.products-detail-list-disclaimer > p.highlight {
  color: #2C3034;
}

@media (max-width: 1199px) {
  .products-list-item {
    padding: 0;
  }
  .products-list-item > * {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  .products-list-item > * > li {
    width: auto;
    border-right: none;
    margin: 0 10px;
    padding: 10px 5px;
  }
  .products-list-item > * > li.products-list-dealTerm, .products-list-item > * > li.products-list-initialRate, .products-list-item > * > li.products-list-fees {
    position: relative;
    grid-column: 1/4;
    border-bottom: solid 1px #F2F2F7;
    text-align: left;
    display: inline;
    padding-left: 0;
    padding-right: 0;
  }
  .products-list-item > * > li.products-list-dealTerm .products-list-item-title, .products-list-item > * > li.products-list-initialRate .products-list-item-title, .products-list-item > * > li.products-list-fees .products-list-item-title {
    position: absolute;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
  .products-list-item-title {
    font-size: 16px;
  }
  .products-list-button .button-action {
    margin-top: 10px;
    padding: 20px 0;
    text-transform: uppercase;
    border-radius: 40px;
  }
  .products-list-logo {
    grid-column: 1/6;
    grid-row: 1;
  }
  .products-list-dealTerm {
    grid-row: 2;
  }
  .products-list-initialRate {
    grid-row: 3;
  }
  .products-list-fees {
    grid-row: 4;
  }
  .products-list-highlight {
    justify-content: center;
    grid-column: 4/6;
    grid-row: 2/5;
    font-size: 12px;
  }
  .products-list-highlight .products-list-item-title {
    font-size: 20px;
  }
  .products-list-button {
    grid-column: 1/6;
    grid-row: 5;
  }
}
@media (max-width: 767px) {
  .button-filter {
    display: block;
  }

  .products {
    margin-left: 0;
    width: 100%;
  }
  .products-header-subtitle {
    margin-bottom: 20px;
  }
  .products-detail-list {
    display: block;
  }
  .products-detail-list > ul {
    width: 100%;
  }
  .products-detail-list > ul > li {
    min-height: auto;
  }
}
.calculator {
  display: flex;
  justify-content: space-between;
}

.form {
  color: #5E7079;
  margin-top: 24px;
}
.form-errorMessage {
  background-color: #FFDFDF;
  margin-top: 20px;
  font-size: 16px;
  padding: 16px;
  color: #E02020;
  border-radius: 4px;
}
.form-field {
  margin-bottom: 30px;
}
.form-field-wrapper {
  display: flex;
  justify-content: space-between;
}
.form-field-title {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 6px;
}
.form-field-result {
  display: none;
  font-size: 12px;
  font-weight: 700;
  color: #1D2C4C;
  margin-left: 10px;
  white-space: nowrap;
}
.form-field-appointment {
  text-align: left;
  color: #8C8C8C;
  margin-top: 20px;
}
.form-field-appointment label {
  font-size: 16px;
  color: #5E7079;
}
.form-input {
  border: solid 1px #D2D2D2;
  border-radius: 4px;
  display: flex;
  height: 60px;
  overflow: hidden;
}
.form-input.error {
  border: solid 1px #E02020;
}
.form-input.error input {
  color: #E02020;
}
.form-input > * {
  height: 100%;
}
.form-input-helper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  flex: 0 0 auto;
  border-right: solid 1px #D2D2D2;
  color: #BEBCBA;
  background-color: #FAF9F8;
  background: url("../../images/pound.svg") center center no-repeat #FAF9F8;
}
.form-input input {
  border: 0;
  padding: 0 16px;
  width: 100%;
  font-size: 18px;
  color: #5E7079;
}
.form-input input.error {
  color: #E02020;
}
.form-input input::placeholder {
  color: #BEBCBA;
}
.form-radio {
  margin-top: 12px;
}
.form-radio label {
  display: inline-block;
  line-height: 22px;
  cursor: pointer;
}
.form-radio label:not(:last-child) {
  margin-right: 25px;
}
.form-radio input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.form-radio input:checked + label {
  color: #00C9E4;
  font-weight: 700;
  border-bottom: solid 2px #00C9E4;
  pointer-events: none;
}
.form-wrapper {
  text-align: center;
  width: 650px;
  margin: 0 auto;
  max-width: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 20px 100px 50px;
  box-shadow: 0 0 5px 0 #ECECEC;
  color: #8C8C8C;
  font-size: 14px;
  line-height: 18px;
}
.form-wrapper .form-field {
  margin-bottom: 10px;
}
.form-wrapper .button-action {
  margin: 30px 0;
}
.form-wrapper-subtitle {
  margin-top: -16px;
}
.form-wrapper-subtitle-left {
  text-align: left;
}
.form-wrapper ol, .form-wrapper ul {
  text-align: initial;
  margin: 15px;
}
.form-wrapper ol {
  list-style-type: decimal;
}

@media (max-width: 767px) {
  .form-wrapper {
    padding: 20px;
  }
  .form-field-appointment label {
    font-size: 14px;
  }
  .form-field-result {
    display: inline-block;
  }
}
.modal {
  z-index: 1;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(55, 67, 85, 0.88);
}
.modal-close {
  position: absolute;
  top: 20px;
  right: 24px;
  width: 24px;
  height: 24px;
  background: url("../../images/close.svg") center center no-repeat;
}
.modal-wrapper {
  position: relative;
  width: 635px;
  max-width: 96%;
  max-height: 96%;
  overflow: auto;
  padding: 60px 12px 25px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(62, 59, 58, 0.5);
}

.productSelector {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.productSelector-wrapper {
  width: 320px;
  max-width: 100%;
}
.productSelector-subtitle {
  color: #8C8C8C;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 14px;
}
.productSelector-list {
  border: solid 1px #D2D2D2;
  background-color: white;
  border-radius: 9px;
}
.productSelector-list > li a {
  text-decoration: none;
  display: block;
  position: relative;
  color: #3E3B3A;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 40px 20px 16px;
}
.productSelector-list > li a:before {
  content: "";
  transform: rotate(-90deg) translateX(50%);
  position: absolute;
  top: 50%;
  right: 16px;
  background: url("../../images/select.svg") center center no-repeat;
  background-size: 12px;
  height: 15px;
  width: 15px;
}
.productSelector-list > li:not(:last-child) a {
  border-bottom: solid 1px #D2D2D2;
}

.success {
  margin: 0 auto;
  text-align: center;
  padding-top: 240px;
  color: #536080;
  width: 440px;
  max-width: 100%;
  line-height: 18px;
  background: url("../../images/success.png") top center no-repeat;
}
.success-button {
  margin-top: 50px;
  width: 100%;
}

.legal {
  font-size: 16px;
  line-height: 22px;
  color: #2C3034;
}
.legal a {
  text-decoration: none;
}
.legal-title {
  font-size: 40px;
  color: #3E3B3A;
  margin-bottom: 45px;
}
.legal-index {
  counter-reset: index;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 60px;
}
.legal-index li a:before {
  counter-increment: index;
  content: counter(index) ". ";
}
.legal-list {
  counter-reset: list;
}
.legal-list > li {
  margin-bottom: 40px;
}
.legal-list p {
  margin-bottom: 16px;
}
.legal-list-title {
  margin-bottom: 8px;
}
.legal-list-title:before {
  counter-increment: list;
  content: counter(list) ". ";
}
.legal-list-ul {
  list-style: initial;
  margin-left: 40px;
  margin-bottom: 20px;
}

.tabs-list {
  display: flex;
  margin-bottom: 50px;
  font-size: 16px;
  font-weight: 700;
  color: #3E3B3A;
}
.tabs-list > li {
  padding: 5px 0;
  cursor: pointer;
}
.tabs-list > li.active {
  padding: 5px 30px;
  background-color: #8C8C8C;
  color: white;
  border-radius: 15px;
  cursor: default;
}
.tabs-list > li:not(:first-child) {
  margin-left: 55px;
}
.tabs-content > li:not(.active) {
  display: none;
}

@media (max-width: 767px) {
  .tabs-list {
    display: block;
    text-align: center;
  }
  .tabs-list > li {
    display: block;
    margin-bottom: 10px;
  }
  .tabs-list > li, .tabs-list > li.active {
    border-radius: 25px;
    padding: 10px;
  }
  .tabs-list > li:not(:first-child) {
    margin-left: 0;
  }
}
/**
* Rangeslider
*/
.rangeslider__handle-label {
  position: absolute;
  margin-top: -22px;
  left: -20px;
  right: -20px;
  text-align: center;
  font-size: 14px;
  color: #536080;
  font-weight: 700;
}

.rangeslider-legend {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #536080;
}

.rangeslider {
  margin: 35px 0 12px;
  position: relative;
  background: #D2D2D2;
  -ms-touch-action: none;
  touch-action: none;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
}

.rangeslider .rangeslider__handle {
  background: white;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 0 6px 0 rgba(182, 182, 182, 0.5);
}

.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}

.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}

.rangeslider .rangeslider__handle-tooltip:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
}

/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 3px;
  border-radius: 4px;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #536080;
  border-radius: 4px;
  top: 0;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}

.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}

/**
* Rangeslider - Vertical slider
*/
.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}

.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}

.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0;
}

.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}

.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}

/**
* Rangeslider - Reverse
*/
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}

/**
* Rangeslider - Labels
*/
.rangeslider__labels {
  position: relative;
}

.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px;
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: "";
  width: 10px;
  height: 2px;
  background: black;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  transform: translate3d(-50%, 0, 0);
}

:root {
  --color-tuna: #373C43;
  --color-white: #FFF;
  --color-pale-sky: #6D757B;
  --color-mandy: #E9596A;
  --color-shark: #2D3136;
  --color-black: #000;
  --color-seashell: #F1F1F1;
  --line-height: 20px;
}

.dd__wrapper {
  position: relative;
}

.dd__opened .dd__selected:after {
  transform: rotate(180deg);
}

.dd__disabled {
  border-color: var(--color-pale-sky);
  color: var(--color-pale-sky);
}

.dd__error {
  border-color: var(--color-mandy);
}

.dd__selectControl {
  border: solid 1px #D2D2D2;
  border-radius: 4px;
  display: flex;
  height: 60px;
  cursor: pointer;
  overflow: hidden;
}
@nest .dd__error & {
  .dd__selectControl {
    color: var(--color-mandy);
    user-select: none;
  }
}
@nest .dd__disabled & {
  .dd__selectControl {
    cursor: default;
  }
}

.dd__selected {
  position: relative;
  padding: 0 40px 0 16px;
  width: 100%;
  font-size: 18px;
  color: #5E7079;
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow: hidden;
}
@nest .dd__multi & {
  .dd__selected {
    margin-top: -2px;
  }
}
.dd__selected:after {
  content: "";
  position: absolute;
  right: 16px;
  top: calc(50% - 5px);
  height: 10px;
  width: 15px;
  transition: transform ease-in-out 0.2s;
  background: url("../../../images/select.svg") center center no-repeat;
}

.dd__search {
  display: inline-block;
  align-items: center;
  flex-grow: 1;
  border: 1px solid transparent;
  outline: 0;
  opacity: 0;
  min-height: var(--line-height);
  word-break: break-word;
}
@nest .dd__multi & {
  .dd__search {
    margin-top: 2px;
  }
}
@nest .dd__opened & {
  .dd__search {
    opacity: 1;
  }
}

.dd__placeholder {
  border: 1px solid transparent;
  color: var(--color-pale-sky);
}
@nest .dd__multi & {
  .dd__placeholder {
    margin-top: 2px;
  }
}
@nest .dd__error & {
  .dd__placeholder {
    color: var(--color-mandy);
    user-select: none;
  }
}

.dd__selectedItem {
  display: flex;
}
@nest .dd__multi & {
  .dd__selectedItem {
    border-color: #A9B6D2;
    background-color: #E3EAF6;
    border-radius: 2px;
    margin-top: 2px;
    margin-right: 2px;
    padding: 0 4px;
  }
}

.dd__crossIcon {
  margin-left: 5px;
}

.dd__expandIcon {
  display: none;
  width: 9px;
  height: 15px;
  flex-shrink: 0;
  fill: var(--color-shark);
}
@nest .dd__error & {
  .dd__expandIcon {
    fill: var(--color-mandy);
  }
}

.dd__list {
  max-height: 200px;
  position: absolute;
  margin-top: -5px;
  padding-bottom: 6px;
  background-color: var(--color-white);
  overflow: auto;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  min-width: 100%;
  border: solid 1px #D2D2D2;
  border-top: none;
  border-radius: 4px;
  /* Without this property, Chrome repaints the entire Grid any time a new row or column is added.
     Firefox only repaints the new row or column (regardless of this property).
     Safari and IE don't support the property at all. */
  will-change: transform;
}
@nest .dd__opened & {
  .dd__list {
    display: block;
  }
  .dd__list:empty {
    display: none;
  }
}

.dd__listVirtualized {
  overflow-y: auto;
  overflow-x: hidden;
}
.dd__listVirtualized :global(.Grid__innerScrollContainer) {
  overflow: hidden;
  position: relative;
}

.dd__optionVirtualized {
  position: absolute;
}

.dd__option {
  margin: 6px;
  padding: 10px;
  color: #536080;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
}
.dd__option.selected {
  display: none;
}
.dd__option:hover {
  background-color: #F2F2F7;
}

.dd__selectedOption {
  background-color: var(--color-seashell);
}

.dd__optionDisabled {
  cursor: default;
}
.dd__optionDisabled:hover {
  background-color: transparent;
}