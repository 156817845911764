.modal {
  z-index: 1;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#374355, 0.88);

  &-close {
    position: absolute;
    top: 20px;
    right: 24px;
    width: 24px;
    height: 24px;
    background: url('../../images/close.svg') center center no-repeat;
  }

  &-wrapper {
    position: relative;
    width: 635px;
    max-width: 96%;
    max-height: 96%;
    overflow: auto;
    padding: 60px 12px 25px;
    background-color: $color-white;
    border-radius: $radius-m;
    box-shadow: $shadow-dark;
  }
}
