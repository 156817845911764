.form {
  color: $color-main-lighter;
  margin-top: 24px;

  &-errorMessage {
    background-color: $color-error-light;
    margin-top: 20px;
    font-size: $font-size-s;
    padding: 16px;
    color: $color-error;
    border-radius: $radius-m;
  }

  &-field {
    margin-bottom: 30px;
    &-wrapper {
      display: flex;
      justify-content: space-between;
    }
    &-title {
      font-size: $font-size-xs;
      line-height: 18px;
      margin-bottom: 6px;
    }

    &-result {
      display: none;
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;
      color: $color-main;
      margin-left: 10px;
      white-space: nowrap;
    }

    &-appointment {
      text-align: left;
      color: $color-base-darker;
      margin-top: 20px;
      label {
        font-size: $font-size-s;
        color: $color-main-lighter;
      }
    }
  }
  
  &-input {
    border: solid 1px $color-base-lighter;
    border-radius: $radius-m;
    display: flex;
    height: 60px;
    overflow: hidden;
    &.error {
      border: solid 1px $color-error;
      input {
        color: $color-error;
      }
    }
    
    > * {
      height: 100%;
    }
    
    &-helper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      flex: 0 0 auto;
      border-right: solid 1px $color-base-lighter;
      color: $color-base-light;
      background-color: $color-base-lightest;
      background: url("../../images/pound.svg") center center no-repeat $color-base-lightest;
    }

    input {
      border: 0;
      padding: 0 16px;
      width: 100%;
      font-size: $font-size-m;
      color: $color-main-lighter;
      &.error {
       color: $color-error;
      }
      &::placeholder {
        color: $color-base-light;
      }
    }
  }

  &-radio {
    margin-top: 12px;
    label {
      display: inline-block;
      line-height: 22px;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 25px;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      &:checked+label {
        color: $color-highlight;
        font-weight: $font-weight-bold;
        border-bottom: solid 2px $color-highlight;
        pointer-events: none;
      }
    }
  }

  &-wrapper {
    text-align: center;
    width: 650px;
    margin: 0 auto;
    max-width: 100%;
    background-color: $color-white;
    border-radius: $radius-m;
    padding: 20px 100px 50px;
    box-shadow: $shadow-m;
    color: $color-base-darker;
    font-size: $font-size-xs;
    line-height: 18px;

    .form-field {
      margin-bottom: 10px;
    }

    .button-action {
      margin: 30px 0;
    }

    &-subtitle {
      margin-top: -16px;
      &-left {
        text-align: left;
      }
    }

    ol, ul {
      text-align: initial;
      margin: 15px;
    }
    ol {
      list-style-type: decimal;
    }
  }
}

@include bp(mobile) {
  .form {
    &-wrapper {
      padding: 20px;
    }
    &-field {
      &-appointment {
        label {
          font-size: $font-size-xs;
        }
      }
      &-result {
        display: inline-block;
      }
    }
  }
}
