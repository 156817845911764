body {
  background-color: $color-base-lightest;
  font-family: $font-main;
  font-weight: $font-weight-regular;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.layout {
  padding: 32px;
}

a {
  color: $color-highlight;
}

@include bp(tablet) {
  .layout {
    padding: 24px 16px;
  }
}

@include bp(mobile) {
  .showMenu {
    .products, .header, .footer {
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
    .aside {
      left: 0;
      .aside-cta {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
