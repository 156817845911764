button {
  border: none;
  cursor: pointer;
  font-family: $font-main;

  &:disabled {
    &, &:hover {
      background-color: rgba($color-action, 0.12);
      cursor: not-allowed;
    }
  }
}

.button {
  &-action {
    text-decoration: none;
    display: inline-block;
    border-radius: 24px;
    background-color: $color-action;
    color: $color-white;
    padding: 16px 0;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    border: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $color-action-dark;
    }
  }
  &-filter {
    text-decoration: none;
    display: inline-block;
    border-radius: 24px;
    background-color: $color-info;
    color: $color-white;
    padding: 14px 30px;
    font-size: $font-size-xxs;
    font-weight: $font-weight-semibold;
    border: none;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    &:before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url('../../images/refine.svg') top center no-repeat;
      background-size: cover;
      margin-right: 10px;
      margin-bottom: -2px;
    }

    &:hover {
      background-color: darken($color-info, 5%);
    }
  }

  &-big {
    width: 315px;
    max-width: 100%;
  }
}
