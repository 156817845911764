.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 32px;
  background-color: $color-white;
  box-shadow: $shadow-l;

  &-logo {
    height: 35px;
  }

  &-close {
    background: url("../../images/close.svg") center center no-repeat $color-main-lightest;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
}

@include bp(tablet) {
  .header {
    padding: 0 20px;
  }
}
